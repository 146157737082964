import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { Box, Text } from '../Shared/Base'
// import Avis from './Avis'
import { dataLayer } from '../../store/analytics.middleware'
import { useScript } from '../Shared/hooks'
import LandingAgenceModal from './Agence.Modal'

const IconCheck = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.6999 2.25243C24.5835 2.92537 24.7626 4.19825 24.0999 5.09549L12.0999 21.3415C11.7521 21.8125 11.22 22.107 10.6417 22.1487C10.0634 22.1905 9.49571 21.9753 9.08574 21.5591L1.08578 13.436C0.304739 12.643 0.304739 11.3572 1.08578 10.5641C1.86683 9.77104 3.13315 9.77104 3.9142 10.5641L10.2836 17.0315L20.8999 2.65858C21.5626 1.76134 22.8162 1.5795 23.6999 2.25243Z"
      fill="#6EA668"
    />
  </svg>
)

const LandingStyled = styled.main`
  color: var(--colorN_primary);

  .pro_title {
    font-family: 'montserrat';
  }

  .btn.is-medium {
    font-size: 1.8rem;
    padding: 16px 30px;
  }

  .h1-like {
    font-family: 'montserrat';
    font-size: 4.4rem;
    line-height: 5.4rem;

    @media screen and (max-width: 1439px) {
      font-size: 3.6rem;
      line-height: 4.6rem;
    }
  }

  .h2-title {
    font-weight: normal;
    font-size: 3rem;
    line-height: 4rem;

    @media screen and (max-width: 1439px) {
      font-size: 2.4rem;
      line-height: 3.5rem;
    }
  }

  .h2-like {
    font-family: 'montserrat';
    font-size: 3.6rem;
    line-height: 4.6rem;

    @media screen and (max-width: 1439px) {
      font-size: 3.2rem;
      line-height: 4.2rem;
    }
  }

  .table-wrapper {
    @media screen and (max-width: 768px) {
      min-width: 100%;
      overflow: none;
      overflow-x: auto;
      overflow-x: scroll;
      display: block;
    }
  }

  .table {
    border-collapse: collapse;
    margin: 0 auto;
    padding: 0;
    max-width: 1060px;
    min-width: 768px;
    width: 100%;
    table-layout: fixed;
    border-radius: 10px;
    border: 1px solid white;
    box-shadow: 0px 10px 30px rgba(232, 244, 254, 0.5);

    @media screen and (min-width: 768px) {
      overflow: hidden;
    }
  }

  .table tr {
    background-color: white;
    padding: 20px;
  }

  .table td {
    border: 1px solid #f1f1f1;
  }

  .table th,
  .table td {
    height: 70px;
    text-align: center;
    padding: 20px;
  }

  .table th {
    font-size: 0.85em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  .table tr td:first-child {
    text-align: left;
  }

  .table tr:first-child {
    height: 90px;
  }
`

const FirstBox = styled(Box)`
  background: linear-gradient(180deg, rgba(162, 221, 255, 0.24) 0%, rgba(255, 255, 255, 0) 33.47%);
  position: relative;

  @media screen and (max-width: 959px) {
    br {
      display: none;
    }
  }

  .illustration {
    @media screen and (max-width: 1140px) {
      width: 450px;
    }
    @media screen and (max-width: 1000px) {
      width: 400px;
    }
  }
`

function isCalendlyEvent(e) {
  return e.data.event && e.data.event.indexOf('calendly') === 0
}

const LandingAgence = () => {
  useScript('https://assets.calendly.com/assets/external/widget.js')
  const [modal_open, set_modal_open] = React.useState(false)
  const onCtaDownloadClick = React.useCallback(() => {
    dataLayer.push({
      event: 'eventGA',
      category: 'LandingAgence',
      action: 'CTAClick'
    })
    set_modal_open(true)
  }, [])

  const onCalendlyCreateClick = React.useCallback(() => {
    window.Calendly.initPopupWidget({
      url: 'https://calendly.com/d/cn2-hhf-k8t/presentation-de-l-offre-pro-jinka'
    })
  }, [])

  React.useEffect(() => {
    // https://help.calendly.com/hc/en-us/articles/360020052833
    window.addEventListener('message', function(e) {
      if (isCalendlyEvent(e)) {
        dataLayer.push({
          event: 'eventGA',
          category: 'LandingAgence',
          action: e.data.event
        })
      }
    })
  }, [])

  return (
    <LandingStyled>
      {modal_open ? (
        <LandingAgenceModal
          onCalendlyCreateClick={onCalendlyCreateClick}
          onClose={() => set_modal_open(false)}
        />
      ) : null}
      <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" />
      <Helmet>
        <title>Le portail qui vous redonne le pouvoir - Jinka</title>
        <meta
          name="description"
          content="Gagnez en visibilité et crédibilité grâce aux annonces certifiées envoyées en temps réel à nos deux millions d'utilisateurs."
        />
        <meta property="og:title" content="Le portail qui vous redonne le pouvoir - Jinka" />
        <meta
          property="og:description"
          content="Gagnez en visibilité et crédibilité grâce aux annonces certifiées envoyées en temps réel à nos deux millions d'utilisateurs."
        />
        <meta property="og:url" content="https://www.jinka.fr/pro" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/loueragile/image/upload/v1619681178/web/jinka/Image_OG_agences.jpg"
        />
        <link rel="canonical" href="https://www.jinka.fr/pro" />
      </Helmet>

      <FirstBox
        as="section"
        maxWidth="100%"
        mx="auto"
        px={{ lg: 0, xs: 20 }}
        pt={{ md: 160, xs: 100 }}
        pb={{ md: 100, xs: 70 }}
      >
        <Box
          maxWidth={1200}
          mx="auto"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          position="relative"
        >
          <Box pt={50} pb={{ ml: 50, xs: 0 }} width={{ lg: 'auto', xs: '100%' }}>
            <Box
              as="h1"
              className="h1-like"
              color="n_secondary"
              textAlign={{ md: 'left', xs: 'center' }}
            >
              Le portail qui vous <br /> redonne le pouvoir
            </Box>
            <Box
              as="h1"
              className="h2-title"
              textAlign={{ md: 'left', xs: 'center' }}
              mt={10}
              mr={{ md: 105, xs: 0 }}
            >
              Gagnez en visibilité et crédibilité grâce aux annonces certifiées envoyées en temps
              réel à nos deux millions d'utilisateurs.
            </Box>
            <Box display="flex" justifyContent={{ md: 'flex-start', xs: 'center' }}>
              <Box
                as="button"
                className="btn is-n_primary is-big"
                onClick={onCtaDownloadClick}
                textAlign="center"
                mt={40}
              >
                Essayer gratuitement
              </Box>
            </Box>
            <Box
              display={{ sm: 'flex', xs: 'block' }}
              alignItems="center"
              mt={{ sm: 35, xs: 20 }}
              justifyContent={{ md: 'left', xs: 'center' }}
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <Text fontSize={22} mr={10}>
                  Noté
                </Text>
                <Text fontSize={22} color="n_secondary">
                  <strong>4.8</strong>
                </Text>
                <Box
                  as="img"
                  src="https://res.cloudinary.com/loueragile/image/upload/v1601450826/web/jinka/Stars.svg"
                  alt="Étoile"
                  ml={10}
                  mr={{ sm: 10, xs: 0 }}
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                mr={{ sm: 10, xs: 0 }}
              >
                <Text fontSize={22}>sur les stores avec</Text>
              </Box>
              <Box
                fontSize={22}
                display={{ sm: 'flex', xs: 'block' }}
                alignItems="center"
                justifyContent="center"
                mt="5px"
                textAlign="center"
              >
                <Box as="p" color="n_secondary">
                  <strong>+ de 50 000 avis !</strong>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            as="img"
            src="https://res.cloudinary.com/loueragile/image/upload/v1675950856/web/jinka/Screen-app-agence.png"
            alt="Application"
            display={{ md: 'block', xs: 'none' }}
            className="illustration"
            // ml={125}
          />
        </Box>
      </FirstBox>

      <Box as="section" maxWidth={1200} mx="auto" textAlign="center" mb={{ ml: '-20px', xs: 0 }}>
        <Box
          display={{ ml: 'inline-flex', xs: 'block' }}
          alignItems="center"
          fontSize="2.8rem"
          backgroundColor="white"
          boxShadow="0px 10px 50px #E8F4FE"
          borderRadius={20}
          padding={30}
          fontWeight="bold"
        >
          <Text>Diffusion en illimité d'annonces à partir de</Text>
          <Box
            as="span"
            mx={10}
            px={10}
            py="5px"
            backgroundColor="n_secondary"
            color="white"
            borderRadius={10}
            display="inline-block"
            my={{ ml: 0, xs: 20 }}
          >
            99 € / mois
          </Box>
          <Text>sans engagement</Text>
        </Box>
      </Box>

      <Box as="section" px={{ lg: 0, xs: 20 }} backgroundColor="#F6FBFF" pb={70} pt={80}>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={{ ml: 'row', xs: 'column' }}
          textAlign="center"
          maxWidth={750}
          mx="auto"
        >
          <Box maxWidth="100%">
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1673357811/web/jinka/Icon-stat-01.png"
              alt=""
            />
            <Box fontSize={34} color="n_secondary" mt={20}>
              <strong>1000 +</strong>
            </Box>
            <Text className="fz-24">
              agences <br /> clientes
            </Text>
          </Box>
          <Box maxWidth="100%" mt={{ ml: 0, xs: 30 }}>
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1673357811/web/jinka/Icon-stat-02.png"
              alt=""
            />
            <Box fontSize={34} color="n_secondary" mt={20}>
              <strong>2 000 000</strong>
            </Box>
            <Text className="fz-24">
              utilisateurs <br />
              en France
            </Text>
          </Box>
          <Box maxWidth="100%" mt={{ ml: 0, xs: 30 }}>
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1673357811/web/jinka/Icon-stat-03.png"
              alt=""
            />
            <Box fontSize={34} color="n_secondary" mt={20}>
              <strong>100 000 +</strong>
            </Box>
            <Text className="fz-24">
              contacts <br />
              par mois
            </Text>
          </Box>
        </Box>
      </Box>

      <Box as="section" maxWidth={1200} mx="auto" mt={{ ml: 90, xs: 45 }} px={{ lg: 0, xs: 20 }}>
        <Box textAlign="center" mb={{ ml: 60, xs: 30 }}>
          <Box as="h2" className="h2-like">
            Notre offre{' '}
            <Box as="span" color="n_secondary">
              Jinka Pro
            </Box>{' '}
            pour les agences immobilières
          </Box>
          <Text className="fz-30" color="n_secondary">
            Vous permet de doubler le nombre de contacts sur vos annonces
          </Text>
        </Box>

        <Box
          display="flex"
          flexDirection={{ ml: 'row', xs: 'column' }}
          alignItems="center"
          justifyContent="center"
          mx="auto"
          mt={{ ml: 60, xs: 0 }}
        >
          <Box mr={{ ml: 55, xs: 0 }} width={{ ml: 400, xs: 'auto' }}>
            <Box textAlign={{ ml: 'left', xs: 'center' }} className="fls">
              <Box
                as="img"
                src="https://res.cloudinary.com/loueragile/image/upload/v1701696258/web/jinka/pro_illus_01.svg"
                alt="Icon"
              />
            </Box>
            <Text
              fontSize={{ ml: '3.0rem', xs: '3.0rem' }}
              mb={{ ml: 10, xs: 10 }}
              fontWeight="700"
              color="n_primary"
              className="pro_title"
              textAlign={{ ml: 'left', xs: 'center' }}
            >
              Visibilité
            </Text>
            <Box display="flex" flexDirection={{ ml: 'row', xs: 'column' }} alignItems="center">
              <IconCheck />
              <Box
                ml={{ sm: 15, xs: 0 }}
                textAlign={{ ml: 'left', xs: 'center' }}
                mt={{ ml: 0, xs: 10 }}
              >
                <Text fontSize={{ ml: '2.4rem', xs: '2.4rem' }} lineHeight="2.8rem">
                  Vos annonces en tête de liste
                </Text>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={{ ml: 'row', xs: 'column' }}
              alignItems="center"
              mt={25}
            >
              <IconCheck />
              <Box
                ml={{ sm: 15, xs: 0 }}
                textAlign={{ ml: 'left', xs: 'center' }}
                mt={{ ml: 0, xs: 10 }}
              >
                <Text fontSize={{ ml: '2.4rem', xs: '2.4rem' }} lineHeight="2.8rem">
                  Renotification de vos biens
                </Text>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={{ ml: 'row', xs: 'column' }}
              alignItems="center"
              mt={25}
            >
              <IconCheck />
              <Box
                ml={{ sm: 15, xs: 0 }}
                textAlign={{ ml: 'left', xs: 'center' }}
                mt={{ ml: 0, xs: 10 }}
              >
                <Text fontSize={{ ml: '2.4rem', xs: '2.4rem' }} lineHeight="2.8rem">
                  Fiche agence personnalisée
                </Text>
              </Box>
            </Box>
          </Box>
          <Box mt={{ ml: 0, xs: 40 }} width={{ ml: 540, xs: 'auto' }}>
            <Box textAlign={{ ml: 'left', xs: 'center' }} className="fls">
              <Box
                as="img"
                src="https://res.cloudinary.com/loueragile/image/upload/v1701696258/web/jinka/pro_illus_02.svg"
                alt="Icon"
              />
            </Box>
            <Text
              fontSize={{ ml: '3.0rem', xs: '3.0rem' }}
              mb={{ ml: 10, xs: 10 }}
              fontWeight="700"
              color="n_primary"
              className="pro_title"
              textAlign={{ ml: 'left', xs: 'center' }}
            >
              Réactivité
            </Text>
            <Box display="flex" flexDirection={{ ml: 'row', xs: 'column' }} alignItems="center">
              <IconCheck />
              <Box
                ml={{ sm: 15, xs: 0 }}
                textAlign={{ ml: 'left', xs: 'center' }}
                mt={{ ml: 0, xs: 10 }}
              >
                <Text fontSize={{ ml: '2.4rem', xs: '2.4rem' }} lineHeight="2.8rem">
                  Passerelle en direct avec votre logiciel
                </Text>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={{ ml: 'row', xs: 'column' }}
              alignItems="center"
              mt={25}
            >
              <IconCheck />
              <Box
                ml={{ sm: 15, xs: 0 }}
                textAlign={{ ml: 'left', xs: 'center' }}
                mt={{ ml: 0, xs: 10 }}
              >
                <Text fontSize={{ ml: '2.4rem', xs: '2.4rem' }} lineHeight="2.8rem">
                  Publication et mise à jour en temps réel
                </Text>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={{ ml: 'row', xs: 'column' }}
              alignItems="center"
              mt={25}
            >
              <IconCheck />
              <Box
                ml={{ sm: 15, xs: 0 }}
                textAlign={{ ml: 'left', xs: 'center' }}
                mt={{ ml: 0, xs: 10 }}
              >
                <Text fontSize={{ ml: '2.4rem', xs: '2.4rem' }} lineHeight="2.8rem">
                  Support technique dédié
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection={{ ml: 'row', xs: 'column' }}
          alignItems="center"
          justifyContent="center"
          mx="auto"
          mt={{ ml: 60, xs: 0 }}
        >
          <Box mr={{ ml: 55, xs: 0 }} mt={{ ml: 0, xs: 40 }} width={{ ml: 400, xs: 'auto' }}>
            <Box textAlign={{ ml: 'left', xs: 'center' }} className="fls">
              <Box
                as="img"
                src="https://res.cloudinary.com/loueragile/image/upload/v1701696259/web/jinka/pro_illus_photo.jpg"
                alt="Photo"
                width="371px"
                height="269px"
              />
            </Box>
          </Box>
          <Box mt={{ ml: 0, xs: 40 }} width={{ ml: 540, xs: 'auto' }}>
            <Box textAlign={{ ml: 'left', xs: 'center' }} className="fls">
              <Box
                as="img"
                src="https://res.cloudinary.com/loueragile/image/upload/v1701696258/web/jinka/pro_illus_03.svg"
                alt="Icon"
              />
            </Box>
            <Text
              fontSize={{ ml: '3.0rem', xs: '3.0rem' }}
              mb={{ ml: 10, xs: 10 }}
              fontWeight="700"
              color="n_primary"
              className="pro_title"
              textAlign={{ ml: 'left', xs: 'center' }}
            >
              Qualité
            </Text>
            <Box display="flex" flexDirection={{ ml: 'row', xs: 'column' }} alignItems="center">
              <IconCheck />
              <Box
                ml={{ sm: 15, xs: 0 }}
                textAlign={{ ml: 'left', xs: 'center' }}
                mt={{ ml: 0, xs: 10 }}
              >
                <Text fontSize={{ ml: '2.4rem', xs: '2.4rem' }} lineHeight="2.8rem">
                  Certification de vos annonces
                </Text>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={{ ml: 'row', xs: 'column' }}
              alignItems="center"
              mt={25}
            >
              <IconCheck />
              <Box
                ml={{ sm: 15, xs: 0 }}
                textAlign={{ ml: 'left', xs: 'center' }}
                mt={{ ml: 0, xs: 10 }}
              >
                <Text fontSize={{ ml: '2.4rem', xs: '2.4rem' }} lineHeight="2.8rem">
                  Contacts en direct : messages + appels
                </Text>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={{ ml: 'row', xs: 'column' }}
              alignItems="center"
              mt={25}
            >
              <IconCheck />
              <Box
                ml={{ sm: 15, xs: 0 }}
                textAlign={{ ml: 'left', xs: 'center' }}
                mt={{ ml: 0, xs: 10 }}
              >
                <Text fontSize={{ ml: '2.4rem', xs: '2.4rem' }} lineHeight="2.8rem">
                  Qualification des leads acquéreurs et vendeurs
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        as="section"
        textAlign="center"
        mt={{ sm: 60, xs: 40 }}
        mb={{ md: 120, xs: 60 }}
        px={{ lg: 0, xs: 20 }}
      >
        <Box
          as="button"
          className="btn is-n_primary is-big"
          onClick={onCtaDownloadClick}
          textAlign="center"
        >
          Essayer gratuitement
        </Box>
      </Box>

      <Box
        as="section"
        backgroundColor="#F6FBFF"
        mt={{ ml: 90, xs: 45 }}
        px={{ lg: 0, xs: 20 }}
        py={70}
      >
        <Box textAlign="center">
          <Box as="h2" className="h2-like" color="n_secondary" mb={30}>
            + 1000 agences partenaires
          </Box>
          <img
            src="https://res.cloudinary.com/loueragile/image/upload/v1699346293/web/jinka/Logos-agences.png"
            alt=""
          />
          <Text fontSize="3.0rem" color="n_secondary" mt={30}>
            Et bien d’autres
          </Text>
        </Box>
      </Box>

      <Box as="section" maxWidth={1120} mx="auto" mt={{ ml: 100, xs: 60 }} px={{ lg: 0, xs: 20 }}>
        <Box textAlign="center">
          <Box as="h2" className="h2-like">
            Comment ça marche ?
          </Box>
          <Text className="fz-30" color="n_secondary">
            Vous n'avez rien à faire, nous nous occupons de tout
          </Text>
        </Box>

        <Box
          display="flex"
          flexDirection={{ ml: 'row', xs: 'column' }}
          mx="auto"
          mt={{ ml: 60, xs: 60 }}
        >
          <Box width={{ ml: '50%', xs: '100%' }}>
            <Box display="flex" flexDirection={{ sm: 'column', xs: 'column' }} alignItems="center">
              <Box
                as="img"
                src="https://res.cloudinary.com/loueragile/image/upload/v1619680266/web/jinka/Number-01.svg"
                alt=""
              />
              <Box textAlign="center" mt={{ sm: 25, xs: 15 }}>
                <Text className="fz-24">
                  Création d’une passerelle avec votre logiciel de diffusion ou dépôt d’annonce sur
                  votre espace Pro
                </Text>
              </Box>
              <Box
                as="img"
                src="https://res.cloudinary.com/loueragile/image/upload/v1701696258/web/jinka/how_01.png"
                alt=""
                mt={25}
              />
            </Box>
          </Box>
          <Box width={{ ml: '50%', xs: '100%' }} mt={{ ml: 0, xs: 50 }}>
            <Box
              display="flex"
              flexDirection={{ sm: 'column', xs: 'column' }}
              alignItems="center"
              width={{ ml: '430px', xs: '100%' }}
              mx="auto"
            >
              <Box
                as="img"
                src="https://res.cloudinary.com/loueragile/image/upload/v1619680266/web/jinka/Number-02.svg"
                alt=""
              />
              <Box textAlign="center" mt={{ sm: 25, xs: 15 }}>
                <Text className="fz-24">
                  Envoi de vos annonces par notification aux utilisateurs en temps réel
                </Text>
              </Box>
              <Box
                as="img"
                src="https://res.cloudinary.com/loueragile/image/upload/v1701696258/web/jinka/how_02.png"
                alt=""
                mt={25}
              />
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection={{ sm: 'column', xs: 'column' }}
          alignItems="center"
          mx="auto"
          mt={{ ml: 60, xs: 50 }}
        >
          <Box
            as="img"
            src="https://res.cloudinary.com/loueragile/image/upload/v1619680266/web/jinka/Number-03.svg"
            alt=""
          />
          <Box textAlign="center" mt={{ sm: 25, xs: 15 }} width={{ ml: '380px', xs: '100%' }}>
            <Text className="fz-24">Gestion de tous vos contacts sur votre compte Jinka Pro</Text>
          </Box>
          <Box
            as="img"
            src="https://res.cloudinary.com/loueragile/image/upload/v1701696259/web/jinka/how_03.png"
            alt=""
            mt={25}
          />
        </Box>
      </Box>

      <Box
        as="section"
        textAlign="center"
        mt={{ sm: 60, xs: 40 }}
        mb={{ md: 120, xs: 60 }}
        px={{ lg: 0, xs: 20 }}
      >
        <Box
          as="button"
          className="btn is-n_primary is-big"
          onClick={onCtaDownloadClick}
          textAlign="center"
        >
          Essayer gratuitement
        </Box>
      </Box>
    </LandingStyled>
  )
}

export default LandingAgence
